<template>
  <div id="app">
    <HeaderBlock />
    <router-view />
    <FooterBlock />
  </div>
</template>
<script>
import HeaderBlock from "@/components/HeaderBlock.vue";
import FooterBlock from "@/components/FooterBlock.vue";
export default {
  components: {
    HeaderBlock,
    FooterBlock,
  },
};
</script>

<style>
#app {
  position: relative;
  font-family: "Montserrat", sans-serif;
  font-weight: 300;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
body {
  margin: 0;
}
nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
