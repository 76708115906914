<template>
  <header class="header">
    <div class="header__wrap desktop">
      <div class="header__logo">
        <img src="@/assets/logo.svg" alt="" />
      </div>
      <ul class="header__links">
        <li class="header__links-item">
          <router-link class="header__links-link" to="/">ГОЛОВНА</router-link>
        </li>
        <li class="header__links-item">
          <router-link class="header__links-link" to="/about"
            >ПРО НАС</router-link
          >
        </li>
      </ul>
    </div>
    <div class="header__wrap_mobile">
      <div class="header__top_mobile">
        <div class="header__logo">
          <img src="@/assets/logo.svg" alt="" />
        </div>
        <div class="header__burger" @click="burgerVisible = !burgerVisible">
          <img src="@/assets/menu.svg" alt="" />
        </div>
      </div>
      <transition name="fade">
        <div class="header__inner_mobile" v-if="burgerVisible">
          <ul class="header__links">
            <li class="header__links-item">
              <router-link class="header__link_mobile" to="/"
                >ГОЛОВНА</router-link
              >
            </li>
            <li class="header__links-item">
              <router-link class="header__link_mobile" to="/about"
                >ПРО НАС</router-link
              >
            </li>
          </ul>
        </div>
      </transition>
    </div>
  </header>
</template>
<script>
export default {
  watch: {
    "$route.params"(val) {
      if (val) this.burgerVisible = false;
    },
  },
  data() {
    return {
      burgerVisible: false,
    };
  },
};
</script>

<style scoped>
.header {
  padding: 10px 30px;
  background-color: rgb(246, 246, 246);
}
.header__wrap {
  display: flex;
  align-items: center;
  margin-right: 100px;
}
.header__logo {
  width: 100px;
  /* height: 50px; */
}
.header__logo img {
  width: 100%;
}
.header__links {
  width: calc(100% - 100px);
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
  justify-content: center;
}
.header__links-item {
  display: block;
  list-style-type: none;
}
.header__links-item:not(:last-child) {
  margin-right: 50px;
}
.header__links-link,
.header__link_mobile {
  color: inherit;
  text-decoration: none;
}
.header__links-link.router-link-exact-active,
.header__link_mobile.router-link-exact-active {
  font-weight: 700;
}
.desktop {
  display: flex;
}
.header__wrap_mobile {
  display: none;
}
.header__top_mobile {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media screen and (max-width: 991px) {
  .desktop {
    display: none;
  }
  .header {
    position: sticky;
    top: 0;
  }
  .header__links-link {
    display: none;
  }
  .header__links {
    width: 100%;
    display: block;
    text-align: left;
  }
  .header__links-item {
    margin-right: 0 !important;
    margin-bottom: 20px;
  }
  .header__wrap_mobile {
    display: block;
  }
  .header__inner_mobile {
    display: block;
    padding: 30px 0 10px;
    position: absolute;
    padding: 10px 30px;
    background-color: rgb(246, 246, 246);
    top: 93px;
    left: 0;
    right: 0;
    height: 75px;
    /* height: 75px; */
  }
}
.fade-enter-active,
.fade-leave-active {
  transition: all 0.2s linear;
  /* transition: height 0.5s linear; */
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  height: 0;
  opacity: 0;
}
</style>
